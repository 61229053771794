import React from "react";
import styled from "styled-components";
import { Typography, Grid } from "@material-ui/core";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import * as PropTypes from "prop-types";
import GatsbyImage from "gatsby-image";
import { OoTypoGrey } from "../element/page-element/OoTypo";
import OoSpacing from "../element/page-element/OoSpacing";
import { lessOrEqualThan } from "../util/mediaQuery";


const ExplainerSection = styled.section`
  margin-top: ${props => props.marginTop};
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
`;
const StyledTrendingFlatIcon = styled(TrendingFlatIcon)`
  height: 5rem;
  margin: 0 2rem;
  ${lessOrEqualThan.md`
    margin: 1rem 0;
    transform: rotate(90deg);
  `};
`;
const LikedDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: left;
  ${lessOrEqualThan.sm`text-align: center;`};
`;
const DiscoveredDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  ${lessOrEqualThan.md`flex-flow: column wrap;`};
  justify-content: center;
  align-items: center;
  text-align: left;
  ${lessOrEqualThan.md`text-align: center;`};
`;
const gatsbyImageStyle = {
  display: "inline-block",
  width: "10rem",
  marginTop: "1rem",
  marginRight: "0.3rem",
};

const text = [
  "Discover new Games",
  "Be a detective like in L.A. Noire",
  "Uncover a story like in Firewatch",
  "Observe others like in Orwell",
  "L.A. Noire",
  "Firewatch",
  "Orwell: Keeping an Eye On You",
  "Discover Her Story",
  "Explore new games based on what you played in the past",
  "Because Cloud liked:",
  "He discovered:",
];

const ExplainerOberion = ({ covers, marginTop }) => {
  const cover0 = covers[0].node.childImageSharp.fluid;
  const cover1 = covers[1].node.childImageSharp.fluid;
  const cover2 = covers[2].node.childImageSharp.fluid;
  const cover3 = covers[3].node.childImageSharp.fluid;

  return (
    <ExplainerSection marginTop={marginTop}>

      <Typography variant="h3" gutterBottom>{text[0]}</Typography>
      <OoTypoGrey variant="subtitle1" gutterBottom>{text[8]}</OoTypoGrey>
      <OoSpacing marginTop="2rem" />

      <Grid container spacing={0} direction="row" alignItems="center" justify="center">
        <Grid item xs={12} sm={12} md={8}>
          <LikedDiv>
            <div>
              <OoTypoGrey variant="body1">{text[9]}</OoTypoGrey>
              <GatsbyImage fluid={cover0} alt={text[5]} style={gatsbyImageStyle} />
              <GatsbyImage fluid={cover1} alt={text[6]} style={gatsbyImageStyle} />
              <GatsbyImage fluid={cover2} alt={text[7]} style={gatsbyImageStyle} />
            </div>
          </LikedDiv>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <DiscoveredDiv>
            <StyledTrendingFlatIcon />
            <div>
              <OoTypoGrey variant="body1">{text[10]}</OoTypoGrey>
              <GatsbyImage fluid={cover3} alt={text[7]} style={gatsbyImageStyle} />
            </div>
          </DiscoveredDiv>
        </Grid>
      </Grid>
    </ExplainerSection>
  );
};
ExplainerOberion.defaultProps = {
  marginTop: "8rem",
};
ExplainerOberion.propTypes = {
  covers: PropTypes.array.isRequired,
  marginTop: PropTypes.string,
};
export default ExplainerOberion;