import React from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import { graphql, Link as GatsbyLink } from "gatsby";
import styled from "styled-components";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import GatsbyImage from "gatsby-image";
import * as PropTypes from "prop-types";
import Layout from "../components/element/layout/Layout";
import { ButtonRoute, ROUTES } from "../components/navigation/InternalRoute";
import NewsletterBanner from "../components/navigation/footer/NewsletterBanner";
import LayoutSetBackground from "../components/element/layout/LayoutSetBackground";
import theme, { grey8 } from "../components/element/theme";
import Testimonials from "../components/home/Testimonials";
import wave from "../components/home/visual/background-wave-OberionIO.min.svg";
import stars from "../components/home/visual/background-stars-OberionIO.min.svg";
import SEO from "../components/element/SEO";
import ExplainerExploreOthers from "../components/home/ExplainerExploreOthers";
import ExplainerOberion from "../components/home/ExplainerOberion";
import ArchiveNotice from "../components/home/ArchiveNotice";
import { lessOrEqualThan } from "../components/util/mediaQuery";

const seo = {
  title: "Oberion · Discover your next favorite game",
  description: "Analyze your gaming library and get personal recommendations based on what you play. Explore innovative indies and cool new releases.",
};

export const marginTop = "8rem";
const BackgroundDiv = styled.div`
  background-color: #00002c;
  background-image: url(${wave}), url(${stars});
  background-size: contain, auto;
  background-position: bottom center;
  background-repeat: no-repeat, repeat;
  min-height: 38rem;
  padding: 3rem 0.5rem;
  
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
`;
const HeroLeft = styled.div`
  max-width: 36rem;
`;
const ButtonList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${lessOrEqualThan.md`align-items: center;`};
  margin: 1rem 0;
`;
const ButtonSt = styled(Button)`
  margin: 0.4rem 0;
  width: 9rem;
  text-align: left;
`;
const gatsbyImageStyle = {
  margin: "4rem 1rem",
  minWidth: "18rem",
  maxWidth: "19rem",
};

const heading = "Discover your next favorite game";
const subheading = "Oberion analyzes your gaming library and gives you personal recommendations based on what you play";
const text = [
  "Find indie games · Explore your library · Track your collection",
];
const ctaButtons = [
  [ROUTES.LISTS.CELESTE.TO, "Tour"],
  [ROUTES.LIBRARY.TO, "Library"],
  [ROUTES.BACKLOG_CHALLENGE.TO, "Backlog"],
];

const Index = (props) => {
  const { data: { allFile: { edges } } } = props;
  const heroImage = edges.filter(el => el.node.childImageSharp.fluid.originalName === "discover-indie-games-OberionIO-2x.png")[0].node.childImageSharp.fluid;
  const recommendationCovers = edges.filter(
    el => el.node.childImageSharp.fluid.originalName.substring(0, 5) === "cover");
  const testimonialLogos = edges.filter(
    el => el.node.childImageSharp.fluid.originalName.substring(0, 11) === "testimonial");

  return (
    <Layout>
      <SEO {...seo} />
      <ArchiveNotice />
      <BackgroundDiv>

        <Grid container spacing={0} justify="center" >
          <HeroLeft>
            <Typography component="h1" variant="h1" gutterBottom>{heading}</Typography>
            <Typography component="h2" variant="subtitle1">{subheading}</Typography>
            <ButtonList>
              {ctaButtons.map( element => (
                <ButtonSt color="secondary" variant="contained"
                        component={GatsbyLink} to={element[0]} key={element[1]}>
                  {element[1]}
                  <div style={{flexGrow: 1}} />
                  <ArrowForwardIcon />
                </ButtonSt>
              ))}
            </ButtonList>
            <Typography variant="body2" gutterBottom>{text[0]}</Typography>

          </HeroLeft>
          <GatsbyImage fluid={heroImage} alt="Discover Indie Games" style={gatsbyImageStyle} />
        </Grid>

      </BackgroundDiv>
      <NewsletterBanner />
      <LayoutSetBackground backgroundColor={grey8} color={theme.palette.secondary.contrastText}>

        <ExplainerOberion covers={recommendationCovers} marginTop={marginTop} />
        <ExplainerExploreOthers marginTop={marginTop} />
        <Testimonials logos={testimonialLogos} marginTop={marginTop} />
      </LayoutSetBackground>
    </Layout>
  );
};
Index.propTypes = {
  data: PropTypes.object.isRequired,
};
export const query = graphql`
{
  allFile(
    filter: {
      extension: {eq: "png"},
      sourceInstanceName: {eq: "home-visual"}
    },
    sort: {fields: [childImageSharp___fluid___originalName], order: ASC})
  {
    edges {
      node {
      childImageSharp {
          fluid(maxWidth: 300, quality: 50) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`;
export default Index;
