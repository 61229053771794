import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import ooTheme, { ooContentWidth, red4 } from "../element/theme";
import { A_REL, LINK_TO, OutboundLinkThemed } from "../navigation/ExternalLink";

const Banner = styled.div`
  background-color: ${ooTheme.palette.background.paper};
  padding: 3rem 0.5rem;
  text-align: left;
  
  display: flex; 
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;
const RestrictWidth = styled.div`max-width: ${ooContentWidth}rem;`;
const RedTypo = styled(Typography)`
  color: ${red4};
`;
const UlSt = styled.ul`
  list-style-type: square;
  padding-inline-start: 2rem;
`;

const txt = [
  "This is an ",
  "archived version",
  " of my prototype project",
  "Login, Newsletter, and RSS feed are disabled",
  "Feel free to look around",
  "Go back to my Portfolio",
  "Visit the current version of Oberion",
  "Visit \"Oberion v1 | Concept Website for GAMESCOM 2018\"",
  "aka \"Oberion v2 | Trip Advisor for Video Games\"",
];

const ArchiveNotice = () => {
  return (
    <Banner>
      <RestrictWidth>
        <Typography component="h1" variant="h3" gutterBottom>
          {txt[0]}
          <RedTypo component="span" variant="h3" gutterBottom>{txt[1]}</RedTypo>
          {txt[2]}
        </Typography>
        <UlSt>
          <Typography component="li" variant="caption">{txt[8]}</Typography>
          <Typography component="li" variant="caption">{txt[4]}</Typography>
          <Typography component="li" variant="caption" gutterBottom>{txt[3]}</Typography>
          <li>
            <OutboundLinkThemed caption={txt[6]}
                                href={LINK_TO.oberion}
                                target={"_self"}
                                rel={A_REL.ENDORSE} />
          </li>
          <li>
            <OutboundLinkThemed caption={txt[7]}
                                href={LINK_TO.v1}
                                target={"_self"}
                                rel={A_REL.ENDORSE} />
          </li>
          <li><OutboundLinkThemed caption={txt[5]}
                                  href={LINK_TO.portfolio}
                                  target={"_self"}
                                  rel={A_REL.ENDORSE} />
          </li>
        </UlSt>
      </RestrictWidth>
    </Banner>
  );
};
export default ArchiveNotice;