import React from "react";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import theme from "../element/theme";

const useStyles = makeStyles({
  avatar: {
    height: "7rem",
    width: "7rem",
    margin: "1.5rem 0",
    backgroundColor: theme.palette.primary.dark,
    border: `solid 2px ${theme.palette.primary.dark}`,
  },
});

// TODO merge with OoAvatar
const TourUserAvatar = ({ src, alt }) => {
  const classes = useStyles();
  return (
    <Avatar src={src} alt={alt} className={classes.avatar} component="div" />
  );
};
TourUserAvatar.defaultProps = {
  alt: "User Avatar",
};
TourUserAvatar.propTypes = {
  src: PropTypes.node.isRequired,
  alt: PropTypes.string,
};
export default TourUserAvatar;
