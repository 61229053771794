import React from "react";
import styled from "styled-components";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import { OoTypoGrey } from "../element/page-element/OoTypo";
import UserCardSupplier from "./UserCardSupplier";
import { ooContentWidth } from "../element/theme";


const ExplainerSection = styled.div`
  margin-top: ${props => props.marginTop};
  width: 100%;
  max-width: ${ooContentWidth}rem;
`;
const useStyles = makeStyles({
  gridItem: {
    paddingTop: "4rem !important",
  },
});

const text = [
  "See what others explored",
  "Browse through lists of similar games",
  "Browse through lists of similar games",
];

const ExplainerExploreOthers = ({ marginTop }) => {
  const classes = useStyles();
  return (
    <ExplainerSection marginTop={marginTop}>
      <Typography variant="h3" gutterBottom>{text[0]}</Typography>
      <OoTypoGrey variant="subtitle1" gutterBottom>{text[1]}</OoTypoGrey>

      <Grid container spacing={2} direction="row" justify="space-between" alignItems="stretch">
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <UserCardSupplier userId={1} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <UserCardSupplier userId={4} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <UserCardSupplier userId={6} />
        </Grid>
      </Grid>
    </ExplainerSection>
  );
};
ExplainerExploreOthers.defaultProps = {
  marginTop: "8rem",
};
ExplainerExploreOthers.propTypes = {
  marginTop: PropTypes.string,
};
export default ExplainerExploreOthers;