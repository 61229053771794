import React from "react";
import * as PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import user1 from "./visual/user1.jpg";
import user4 from "./visual/user4.jpg";
import user6 from "./visual/user6.jpg";
import ooTheme from "../element/theme";
import { InternalRoute } from "../navigation/InternalRoute";
import TourUserAvatar from "../tour/TourUserAvatar";

const users = [
  {
    userId: 1,
    userName: "pinballboy",
    favoriteGames: "Favorite games: Kabounce, Crosscode, and Rocket League.",
    favoriteGenres: "Favorite genres: Fantasy, Atmospheric, Story",
    avatar: user1,
    linkTo: "/lists/pinballboy",
  },
  {
    userId: 4,
    userName: "motor_",
    favoriteGames: "Favorite games: Rocket League, Need for Speed: Most Wanted (2007), Armello, and The Flame in the Flood.",
    favoriteGenres: "Favorite genres: Racing, Board games, RPG",
    avatar: user4,
    linkTo: "/lists/motor",
  },
  {
    userId: 6,
    userName: "HackNSlash_915",
    favoriteGames: "Favorite games: Hollow Knight, Dead Cells, Sanctuary.",
    favoriteGenres: "Favorite genres: Hack N' Slash, Shooter, Open World.",
    avatar: user6,
    linkTo: "/lists/hacknslash_915",
  },
];

const UserCard = styled.div`
  padding: 2rem;
  margin: 0 auto;
  background: ${ooTheme.palette.background.paper};
  color: ${ooTheme.palette.primary.contrastText};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  text-align: center;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  box-shadow: ${ooTheme.shadows[5]};
`;
const UserAvatar = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: -6.25rem;
  margin-bottom: -5rem;
`;
const UserTypo = styled(Typography)`
  opacity: 0.8;
`;

const UserCardSupplier = ({ userId }) => {
  const user = users.filter(el => el.userId === userId)[0];
  return (
    <UserCard>
      <UserAvatar>
        <TourUserAvatar src={user.avatar} alt={`User avatar from ${user.userName}`} />
      </UserAvatar>
      <Typography variant="h6" gutterBottom>{user.userName}</Typography>
      <UserTypo variant="body1" paragraph>{user.favoriteGames}</UserTypo>
      <UserTypo variant="body1" paragraph>{user.favoriteGenres}</UserTypo>
      <InternalRoute variant="body1" caption="Explore list" to={user.linkTo} />
    </UserCard>
  );
};
UserCardSupplier.propTypes = {
  userId: PropTypes.number.isRequired,
};
export default UserCardSupplier;